import React from "react"
import { graphql, PageProps } from "gatsby"
import { useForm } from "react-hook-form"
import clsx from "clsx"

import Layout from "components/layout"
import SEO from "components/seo"
import { sendEmail } from "helpers/api"
import { X, CheckCircle } from "react-feather"

type Data = {
  site: {
    siteMetadata: {
      social: {
        email: string
        phone: string
      }
      address: string
    }
  }
}

enum Status {
  success = "SUCCESS",
  error = "ERROR",
  loading = "LOADING",
  idle = "IDLE",
}

const About = ({ data, location }: PageProps<Data>) => {
  const address = data.site.siteMetadata.address
  const email = data.site.siteMetadata.social.email
  const phone = data.site.siteMetadata.social.phone

  const [status, setStatus] = React.useState<Status>(Status.idle)

  return (
    <Layout location={location}>
      <SEO title="Contact us" />
      <section className="relative text-gray-700 body-font">
        <div className="container flex flex-wrap px-5 py-32 mx-auto sm:flex-no-wrap">
          <div className="relative flex items-end justify-start p-10 overflow-hidden bg-gray-300 rounded-lg lg:w-2/3 md:w-1/2 sm:mr-10">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              frameBorder="0"
              title="map"
              marginHeight={0}
              marginWidth={0}
              scrolling="no"
              src="https://maps.google.com/maps?q=5965%20W%20Broadway%2C%20McCordsville%2C%20IN%2046055%2C%20United%20States&t=&z=13&ie=UTF8&iwloc=&output=embed"
              style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
            ></iframe>
            <div className="relative flex flex-wrap py-6 bg-white">
              <div className="px-6 lg:w-1/2">
                <h2 className="font-medium tracking-wide text-gray-900 text-md title-font">
                  Address
                </h2>
                <address>
                  <p className="leading-relaxed">{address}</p>
                </address>
              </div>
              <div className="px-6 mt-4 lg:w-1/2 lg:mt-0">
                <h2 className="font-medium tracking-wide text-gray-900 text-md title-font">
                  Email
                </h2>
                <a
                  className="leading-relaxed text-orange"
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
                <h2 className="mt-4 font-medium tracking-wide text-gray-900 text-md title-font">
                  Phone
                </h2>
                <a className="leading-relaxed" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
            </div>
          </div>
          {status === Status.success ? (
            <Success />
          ) : (
            <Form status={status} setStatus={setStatus} />
          )}
        </div>
      </section>
    </Layout>
  )
}

const Form = ({ status, setStatus }) => {
  const { handleSubmit, register, errors, reset } = useForm()
  const onSubmit = values => {
    setStatus(Status.loading)
    sendEmail(values)
      .then(() => {
        setStatus(Status.success)
        reset()
      })
      .catch(err => {
        console.log(err)
        setStatus(Status.error)
      })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full mt-8 lg:w-1/3 md:w-1/2 md:ml-auto md:py-8 md:mt-0"
    >
      {status === Status.error && <ErrorAlert />}
      <h2 className="mb-1 text-2xl font-bold text-gray-900 title-font">
        Contact Us
      </h2>
      <p className="mb-5 leading-relaxed text-gray-600">
        Want to tell us something? Fill the form and get in touch with us!
      </p>

      <div className="mb-4 space-y-4">
        <div className="flex flex-col space-y-3">
          <label htmlFor="name" className="relative font-semibold">
            Name
            <span
              className="absolute top-0 -mt-2 text-3xl text-red-600"
              aria-hidden
            >
              *
            </span>
          </label>
          <input
            id="name"
            className={clsx(
              errors.name && "border-red-600 placeholder-red-600",
              "px-4 py-2 text-base bg-white border border-gray-400 rounded focus:outline-none focus:border-orange-500"
            )}
            placeholder="John Doe"
            name="name"
            type="text"
            ref={register({
              required: "Please fill out this field!",
              maxLength: { value: 120, message: "Name too long." },
            })}
            aria-required
          />
          <span role="alert" className="tracking-normal text-red-600 ">
            {errors.name && errors.name.message}
          </span>
        </div>

        <div className="flex flex-col space-y-3">
          <label htmlFor="email" className="relative font-semibold">
            Email
            <span
              className="absolute top-0 -mt-2 text-3xl text-red-600"
              aria-hidden
            >
              *
            </span>
          </label>
          <input
            id="email"
            className={clsx(
              errors.email && "border-red-600 placeholder-red-600",
              "px-4 py-2 text-base bg-white border border-gray-400 rounded focus:outline-none focus:border-orange-500"
            )}
            placeholder="john.doe@hotmail.com"
            name="email"
            ref={register({
              required: "Please fill out this field!",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            })}
            aria-required
          />
          <span role="alert" className="tracking-normal text-red-600 ">
            {errors.email && errors.email.message}
          </span>
        </div>

        <div className="flex flex-col space-y-3">
          <label htmlFor="message" className="relative font-semibold">
            Message
            <span
              className="absolute top-0 -mt-2 text-3xl text-red-600"
              aria-hidden
            >
              *
            </span>
          </label>
          <textarea
            id="message"
            name="message"
            className={clsx(
              errors.message && "border-red-600 placeholder-red-600",
              "px-4 py-2 text-base bg-white border border-gray-400 rounded focus:outline-none focus:border-orange-500"
            )}
            placeholder="Message"
            ref={register({
              required: "Please fill out this field!",
            })}
            aria-required
          />
          <span role="alert" className="tracking-normal text-red-600 ">
            {errors.message && errors.message.message}
          </span>
        </div>
      </div>

      <button
        className={clsx(
          status === Status.loading && "cursor-not-allowed opacity-50",
          status !== Status.loading && "hover:bg-mustard-darker",
          "px-6 py-2 text-lg font-semibold text-black border-0 rounded bg-mustard focus:outline-none"
        )}
      >
        Submit
      </button>
      <p aria-hidden className="flex items-center mt-2 space-x-2">
        <span className="text-3xl text-red-600 " aria-hidden>
          *
        </span>
        <span className="font-semibold">= Required</span>
      </p>
    </form>
  )
}

const ErrorAlert = () => {
  return (
    <div
      className="px-4 py-3 mb-6 text-teal-900 bg-red-100 border-t-4 border-red-500 rounded-b shadow-sm"
      role="alert"
    >
      <div className="flex items-center space-x-4">
        <div className="py-1">
          <X className="w-6 h-6 text-red-500" />
        </div>
        <div>
          <p className="font-bold">Oops!</p>
          <p className="text-sm">
            Looks like something went wrong on our end. Please try again.
          </p>
        </div>
      </div>
    </div>
  )
}

const Success = () => {
  return (
    <div className="flex flex-col flex-wrap items-center w-full px-5 py-24 mt-8 space-y-8 lg:w-1/3 md:w-1/2 md:ml-auto md:py-8 md:mt-0">
      <CheckCircle className="w-12 h-12 text-teal-500" />
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-2xl font-semibold text-gray-900 title-font">
          Thank you for your time!
        </h1>
        <p className="text-lg leading-relaxed text-center lg:w-2/3">
          We will be contacting you soon.
        </p>
      </div>
    </div>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        social {
          email
          phone
        }
        address
      }
    }
  }
`
