export function sendEmail(data) {
  return fetch("/.netlify/functions/send-email", {
    body: JSON.stringify(data),
    method: "POST",
  }).then(response => {
    if (!response.ok) {
      return Promise.reject(response)
    }
    return response.json()
  })
}
